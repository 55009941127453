export default [{
  'permission': 'caiwu-*',
  'title': '财务',
  sortOrder: 80,
  'icon': 'el-icon-menu',
  'url': '#caiwu',
  'children': [{
  //   'permission': 'caiwu-salary',
  //   'title': '工资条',
  //   'url': '/caiwu/salary-detail'
  // }, {
    'permission': 'caiwu-travel',
    'title': '电子发票',
    'url': '/caiwu/invoice'
  }, {
    'permission': 'caiwu-travel',
    'title': '差旅费',
    'url': '/caiwu/travel'
  }, {
    'permission': 'caiwu-travel',
    'title': '差旅费银行卡',
    'url': '/caiwu/travel-bank'
  }, {
    'permission': 'caiwu-koufu',
    'title': '口福',
    'url': '/caiwu/koufu'
  }, {
    'permission': 'caiwu-device-option',
    'title': '设备概览',
    'url': '/device/summary'
  }, {
    'permission': 'caiwu-device-option',
    'title': '设备属性',
    'url': '/device/option'
  }, {
    'permission': 'caiwu-device-product',
    'title': '设备产品',
    'url': '/device/product'
  }, {
    'permission': 'caiwu-device-item',
    'title': '设备项目',
    'url': '/device/item'
  }, {
    'permission': 'caiwu-dd-project',
    'title': '项目工时',
    'url': '/caiwu/dd-project'
  }]
}]
