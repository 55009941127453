const authUrl =
  process.env.VUE_APP_AUTH_API || process.env.VUE_APP_BASE_API || "";

export const AUTH = {
  AUTH_LOGIN: authUrl + "/hnauth/admin/login",
  AUTH_LOGOUT: authUrl + "/hnauth/admin/logout",
  AUTH_PERMISSION: "/hnauth/user/permission/detail",
  AUTH_SITES: "/hnauth/user/auth/website/get",
  MODIFY_PASSWORD: "/admin/v2/profile/modify-password",
  ADMIN_SUGGEST: "/admin/v2/admin/suggest",
  DEPT_LIST: "/admin/v2/dept/list",
};

export const BASE = {
  OSS_POLICY: "/api-base/v2/oss/policy",
  OSS_STSTOKEN: "/api-base/v2/oss/sts-token",

  QA_HOT_SEARCH_WORD_LIST: "/api-base/v2/post/QA/hot_search_word/get",
  QA_EVENT_TRACKING_ADD: "/api-base/v2/event/tracking/add",

  TERM_LIST: "/api-base/v2/term/list",
  TERM_TAXONOMY_LIST: "/api-base/v2/term_taxonomy/list",
  TERM_TAXONOMY_TREE: "/api-base/v2/term_taxonomy/tree/get",
  TERM_TAXONOMY_CREATE: "/api-base/v2/term_taxonomy/create",
  TERM_TAXONOMY_UPDATE: "/api-base/v2/term_taxonomy/update",
  TERM_TAXONOMY_DELETE: "/api-base/v2/term_taxonomy/delete",

  POST_LIST: "/api-base/v2/post/list",
  POST_CREATE: "/api-base/v2/post/create",
  POST_UPDATE: "/api-base/v2/post/update",
  POST_DELETE: "/api-base/v2/post/delete",
  POST_QA_IMPORT: "/api-base/v2/post/QA/import",
};

export const V1 = {
  ADMIN_AUTH: "/v1/admin/auth",
  ADMIN_AUTH_ASSIGN: "/v1/admin/auth/assign",
  ADMIN_AUTH_DATA_LIST: "/v1/admin/data",
  ADMIN_AUTH_DATA_ASSIGN: "/v1/admin/data/assign",
  ADMIN_AUTH_DATA_REVOKE: "/v1/admin/data/revoke",
  ADMIN_PERMISSION_LIST: "/v1/admin/permission",
  ADMIN_PERMISSION_CREATE: "/v1/admin/permission",
  ADMIN_ROLE_LIST: "/v1/admin/role",
  ADMIN_ROLE_CREATE: "/v1/admin/role",
  ADMIN_ROLE_GET: "/v1/admin/role/view",
  ADMIN_MENU_LIST: "/v1/admin/menu",
  ADMIN_MENU_TREE: "/v1/admin/menu/tree",
  ADMIN_MENU_CREATE: "/v1/admin/menu",
  ADMIN_MENU_UPDATE: "/v1/admin/menu/",
  ADMIN_MENU_DELETE: "/v1/admin/menu/",

  ADMIN_LIST: "/v1/admin",
  ADMIN_SUGGEST: "/v1/admin/suggest",
  ADMIN_LOCK: "/v1/admin/lock",
  ADMIN_UNLOCK: "/v1/admin/unlock",
  ADMIN_CREATE: "/v1/admin",
  ADMIN_UPDATE: "/v1/admin/",

  ADMIN_DEPT_LIST: "/v1/admin/dept",
  ADMIN_DEPT_TREE: "/v1/admin/dept/tree",
  ADMIN_DEPT_CREATE: "/v1/admin/dept",
  ADMIN_DEPT_UPDATE: "/v1/admin/dept/",
  ADMIN_GROUP_LIST: "/v1/admin/group",
  ADMIN_GROUP_CREATE: "/v1/admin/group",
  ADMIN_GROUP_UPDATE: "/v1/admin/group/",

  CAIWU_INVOICE_BATCH_CONFIRM: "/v1/caiwu/invoice/batch-confirm",
  CAIWU_INVOICE_LIST: "/v1/caiwu/invoice",
  CAIWU_INVOICE_MY: "/v1/caiwu/invoice/my",
  CAIWU_INVOICE_CRETEE: "/v1/caiwu/invoice",
  CAIWU_INVOICE_UPDATE: "/v1/caiwu/invoice/",
  CAIWU_INVOICE_DELETE: "/v1/caiwu/invoice/",

  CAIWU_SALARY_LIST: "/v1/caiwu/salary-detail",
  CAIWU_SALARY_MY: "/v1/caiwu/salary-detail/my",
  CAIWU_SALARY_CREATE: "/v1/caiwu/salary-detail",
  CAIWU_SALARY_UPDATE: "/v1/caiwu/salary-detail/",
  CAIWU_SALARY_DELETE: "/v1/caiwu/salary-detail/",

  DEVICE_OPTION_LIST: "/v1/device/option",
  DEVICE_OPTION_TREE: "/v1/device/option/tree",
  DEVICE_OPTION_CREATE: "/v1/device/option",
  DEVICE_OPTION_UPDATE: "/v1/device/option/",
  DEVICE_OPTION_DELETE: "/v1/device/option/",

  DEVICE_PRODUCT_LIST: "/v1/device/product",
  DEVICE_PRODUCT_CREATE: "/v1/device/product",
  DEVICE_PRODUCT_UPDATE: "/v1/device/product/",
  DEVICE_PRODUCT_DELETE: "/v1/device/product/",

  DEVICE_ITEM_SUMMARY: "/v1/device/item/stat",

  DEVICE_ITEM_LIST: "/v1/device/item",
  DEVICE_ITEM_CREATE: "/v1/device/item",
  DEVICE_ITEM_UPDATE: "/v1/device/item/",
  DEVICE_ITEM_DELETE: "/v1/device/item/",

  DEVICE_ITEM_LOG_LIST: "/v1/device/item/log",
  DEVICE_ITEM_LOG_CREATE: "/v1/device/item/log",
  DEVICE_ITEM_LOG_UPDATE: "/v1/device/item/log/",

  CUSTOMER_LIST: "/v1/customer",
  CUSTOMER_MY_LIST: "/v1/customer/mylist",
  CUSTOMER_PUBLIC: "/v1/customer/public",
  CUSTOMER_VIEW: "/v1/customer/view",
  CUSTOMER_CREATE: "/v1/customer/create",
  CUSTOMER_UPDATE: "/v1/customer/update",
  CUSTOMER_RECEIVE: "/v1/customer/receive",
  CUSTOMER_TRANSFER: "/v1/customer/transfer",
  CUSTOMER_FOLLOW_RECORDS: "/v1/customer/follow/record",
  CUSTOMER_FOLLOW_RECORDS_CREATE: "/v1/customer/follow/record/create",
  CUSTOMER_SHOP: "/v1/customer/shop",
  CUSTOMER_SHOP_CREATE: "/v1/customer/shop/create",
  CUSTOMER_SHOP_UPDATE: "/v1/customer/shop/update",
  CUSTOMER_SHOP_SUGGEST: "/v1/customer/shop/suggest",
  CUSTOMER_SHOP_LOG: "/v1/customer/shop/log",
  CUSTOMER_SHOP_FOLLOW_RECORDS: "/v1/customer/shop/follow/record",
  CUSTOMER_SHOP_FOLLOW_RECORDS_CREATE: "/v1/customer/shop/follow/record/create",

  XUQIU_TYPE: "/v1/xuqiu/type",
  XUQIU_TYPE_TREE: "/v1/xuqiu/type/tree",
  XUQIU_TYPE_CREATE: "/v1/xuqiu/type/create",
  XUQIU_TYPE_UPDATE: "/v1/xuqiu/type/update",
  XUQIU_LIST: "/v1/xuqiu",
  XUQIU_MY_PUBLISH: "/v1/xuqiu/my-publish",
  XUQIU_WAIT_HANDLE: "/v1/xuqiu/wait-handle",
  XUQIU_MY_HANDLE: "/v1/xuqiu/my-handle",
  XUQIU_HANDLE: "/v1/xuqiu/handle",
  XUQIU_CREATE: "/v1/xuqiu/create",
  XUQIU_UPDATE: "/v1/xuqiu/update",
  XUQIU_VIEW: "/v1/xuqiu/view",
  XUQIU_LOG: "/v1/xuqiu/log",
  XUQIU_LOG_CREATE: "/v1/xuqiu/log/create",
  XUQIU_LOG_UPDATE: "/v1/xuqiu/log/update",
};

export const MESSAGE = {
  NOTICE_TYPE_LIST: "/api-base/v2/notice/types",
  NOTICE_TYPE_CREATE: "/api-base/v2/notice/msg/type/save",
  NOTICE_TYPE_DELETE: "/api-base/v2/notice/msg/type/del",
  NOTICE_TYPE_MY_LIST: "/api-base/v2/notice/types/mine",
  NOTICE_TYPE_COUNT_LIST: "/api-base/v2/notice/msg/mine/count",
  NOTICE_LIST: "/api-base/v2/notice/msg/list",
  NOTICE_CREATE: "/api-base/v2/notice/msg/save",
  NOTICE_DELETE: "/api-base/v2/notice/msg/del",
  MESSAGE_LIST: "/api-base/v2/notice/msg/mine/list",
  NOTICE_READ: "/api-base/v2/notice/msg/read",
  NOTICE_DETAIL: "/api-base/v2/notice/msg/detail",
};

export const DY_AD = {
  ACCESS_TOKEN_LIST: "/chat-gpt/v2/oauth2/access-token",
};
